*{
    box-sizing: border-box;
}
:root {
	--color-btn-text: rgba(0,0,0,0.8);
    --border-color: rgba(177,177,177,0.45);
    --white-background-color: rgb(255,255,255);
    --theme-color: #FFB600;
    --theme-text-color: rgb(242,138,60);
    --theme-opacity-color: rgba(248,154,84,0.1);
    --theme-opacity-color2: rgba(248,154,84,0.2);
    --theme-opacity-color3: rgba(248,154,84,0.3);
    --theme-opacity-color5: rgba(248,154,84,0.5);
    --error-color: #C30052;
    --success-color: #69C33F;
    --warn-color: #FF9400;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

body{
    margin: 0;
    padding: 0;
}
body>iframe {
	display: none !important;
}
.fullHeight {
	height: 100%;
}

.routerWrapper {
	display: flex;
	height: 100%;
	flex-flow: column wrap;
}

.mainBody {
	position: relative;
	width: 100%;
	height: 0;
	flex: 1;
}

.globalMask {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.65);
	z-index: 999;
	display: none;
}

@media screen and (min-width:769px) {
	.routerWrapper {
		*::-webkit-scrollbar {
			width: 8px;
			background-color: #fafafa;
		}

		*::-webkit-scrollbar-track {
			border-radius: 10px;
			background-color: #fafafa;
		}

		*::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: #dedede;
		}
	}
}


.mybtn{
	width: 72%;
	margin: 20px auto;
	max-width: 280px;
	height: 45px;
	line-height: 45px;
	background-color: var(--theme-color);
	color: var(--color-btn-text);
	border-radius: 10px;
	text-align: center;
	font-size: 18px;
	font-weight: 600;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	transition: all 0.2s;
	&:hover{
		box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3);
	}
}

.loadingRound{
	animation: loadingRound 0.9s linear infinite;
}
@keyframes loadingRound{
	0%{
		transform: rotate(0deg);
	}
	100%{
		transform: rotate(360deg);
	}
}

.error_rocking{
	animation: rocking 0.24s linear;
}
.long_rocking{
	animation: rocking 0.3s linear infinite;
}

@keyframes rocking{
	0%{
		transform: translateX(0px);
	}
	25%{
		transform: translateX(-10px);
	}
	75%{
		transform: translateX(10px);
	}
	100%{
		transform: translateX(0px);
	}
}

.cartPopup{
    letter-spacing: 0.1px;
    max-width: 420px;
    padding: 20px 0;
    font-size: 14px;
    .cartPopupTitle{
        text-align: center;
        padding: 0 20px;
        .cartPopupTitle1{
            font-size: 16px;
            color: var(--theme-text-color);
            font-weight: 600;
            line-height: 35px;
        }
        .cartPopupTitle2{
            font-size: 16px;
            color: #000;
            font-weight: 600;
            line-height: 22px;
        }
        .cartPopupTitle3{
            font-size: 14px;
            color: rgb(130, 130, 130);
            line-height: 30px;
        }
    }
    .cartPopupItems{
        margin-top: 10px;
        border-top: 1px solid #F2F2F2;
        max-height: 285px;
        overflow-y: auto;
        .cartPopupItem{
            display: flex;
            align-items: center;
            height: 54px;
            padding: 0 20px;
            .itemPic{
                width: 36px;
                height: 36px;
                margin-right: 16px;
                img{
                    border-radius: 5px;
                    width: 36px;
                    height: 36px;
                    object-fit: contain;
                }
            }
            .itemName{
                font-size: 14px;
                color: #000;
                flex:1;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }
            .viewDetail{
                height: 23px;
                line-height: 20px;
                border-radius: 10px;
                border: 1px solid #120136;
                padding: 0 8px;
                margin-left: 8px;
                cursor: pointer;
                color: rgb(18, 1, 54);
                font-size: 13px;
            }
            .soldout{
                color: rgb(130, 130, 130);
                margin-left: 8px;
                color: #828282;
                font-size: 12px;
            }
        }
    }
    .pupupbtn{
        background-color: var(--theme-color);
        color: var(--color-btn-text);
        height: 45px;
        line-height: 45px;
        text-align: center;
        width: 76%;
        margin: 20px auto 0;
        font-size: 16px;
        font-weight: bold;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.2s;
        &:hover{
            box-shadow: 0 0 5px rgba(0,0,0,0.2);
        }
    }
}
.tableChangePopup{
    letter-spacing: 0.1px;
    max-width: 420px;
    padding: 20px 0;
    .popupTitle{
        padding: 0 20px;
        .popupTitle1{
            font-size: 16px;
            font-weight: 600;
            line-height: 35px;
            color: var(--theme-text-color);
        }
    }
    .PopupCont{
        padding: 10px 20px;   
        font-size: 14px;     
    }
}

.air-datepicker-global-container{
    z-index: 120;
}